const getDimensions = (ele: any) => {
  const { height } = ele.getBoundingClientRect();

  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

export { getDimensions };
