import type { GetServerSideProps, NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import {
  CoCreation,
  ContactList,
  Features,
  Technology,
  WithSider,
} from 'components/organisms';
import { useContext, useEffect, useRef } from 'react';
import { Context } from 'utils/Context';
import { debounceFunction } from 'utils/debounce';
import { HOME_MENUS } from 'config/SideBarMenus';
import Head from 'next/head';
import { i18n, useTranslation } from 'next-i18next';
import { getDimensions } from 'utils/getDimensions';
import OurWorks from '../components/organisms/OurWorks';
import { News } from 'components/organisms';
import { useRouter } from 'next/router';

const Home: NextPage = (props: any) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { selectedMenu, setSelectedMenu } = useContext(Context);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);

  const debouncedScroll = () => {
    debounceFunction(handleScroll);
  };

  const sectionRefs = [
    { ref: ref1, section: 'co-creation' },
    { ref: ref2, section: 'features' },
    { ref: ref3, section: 'our-work' },
    { ref: ref4, section: 'technology' },
    { ref: ref5, section: 'news' },
    { ref: ref6, section: 'contact-us' },
  ];

  const handleScroll = () => {
    const negativeOffsetY = 450;
    const scrollPosition = window.scrollY + negativeOffsetY;

    if (scrollPosition < 0) {
      setSelectedMenu('-');
      return;
    }

    const selected = sectionRefs.find(({ ref }) => {
      const ele = ref.current;
      if (ele) {
        const { offsetBottom, offsetTop } = getDimensions(ele);

        return (
          scrollPosition > offsetTop && scrollPosition < offsetBottom
        );
      }
    });
    if (selected && selected.section !== selectedMenu) {
      setSelectedMenu(selected.section);
    }
  };

  useEffect(() => {
    const onRouteChangeComplete = (url: string) => {
      if (url === '/') {
        const savedScrollY = localStorage.getItem('scroll');
        if (savedScrollY) {
          window.scrollTo({
            top: parseInt(savedScrollY, 10),
          });
        }
      }
    };

    const saveScrollPosition = () => {
      if (router.pathname === '/') {
        localStorage.setItem('scroll', `${window.scrollY}`);
      }
    };

    router.events.on('routeChangeComplete', onRouteChangeComplete);
    window.addEventListener('scroll', debouncedScroll);
    router.events.on('routeChangeStart', saveScrollPosition);

    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
      window.removeEventListener('scroll', debouncedScroll);
      router.events.off('routeChangeStart', saveScrollPosition);
    };
  }, [router.events, selectedMenu]);

  return (
    <>
      <Head>
        <title>
          {t(
            'Business Co-Creation - Effectively promote new business development and DX through co-creation',
          )}
        </title>
        <link
          rel="canonical"
          href="https://wesionary.team/"
          key="canonical"
        />
        <meta
          property="og:title"
          content={t(
            'Business Co-Creation - Effectively promote new business development and DX through co-creation',
          )}
        />
        <meta property="og:url" content="https://wesionary.team/" />
        <meta
          name="description"
          content={t(
            'The goal is not to create functions and systems, but to help achieve the vision of the business and DX objectives that lie beyond that. We effectively confront increasingly complex business environments and technological innovations as one team using a systematic approach.',
          )}
        ></meta>
        <meta
          property="og:description"
          content={t(
            'The goal is not to create functions and systems, but to help achieve the vision of the business and DX objectives that lie beyond that. We effectively confront increasingly complex business environments and technological innovations as one team using a systematic approach.',
          )}
        />
        <meta
          name="keywords"
          content={t(
            'New Business Development,DX,Business Co-Creation',
          )}
        />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_URL}/og-images/${i18n?.language}/Facebook-top.png`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:image"
          content={`${process.env.NEXT_PUBLIC_URL}/og-images/${i18n?.language}/Twitter-top.png`}
        />
      </Head>
      <WithSider menus={HOME_MENUS}>
        <CoCreation
          ref={ref1}
          id="co-creation"
          videoID={props?.videoID}
        />
        <Features ref={ref2} id="features" />
        <section
          ref={ref3}
          id="our-work"
          className="mx-[-24px] mb-16 lg:mx-0"
        >
          <OurWorks />
        </section>
        <Technology ref={ref4} className="mb-0" id="technology" />
        <News ref={ref5} id="news" />
        <ContactList ref={ref6} id="contact-us" />
      </WithSider>
    </>
  );
};

export default Home;

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  res,
}) => {
  const serviceVideosNumber = Math.floor(Math.random() * 5);
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59',
  );
  return {
    props: {
      ...(await serverSideTranslations(locale || 'en', ['common'])),
      videoID: serviceVideosNumber + 1,
    },
  };
};
